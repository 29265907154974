import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Index',
    component: () => import('../views/index'),
    children: [
      {
        path: '/home',
        name: 'Home',
        component: () => import('../views/home/index'),
      },
      {
        path: '/user',
        name: 'User',
        component: () => import('../views/user/index'),
      },
      {
        path: '/user/detail',
        name: 'UserDetail',
        component: () => import('../views/user/detail'),
      },
      {
        path: '/finance',
        name: 'Finance',
        component: () => import('../views/finance/index'),
      },
      {
        path: '/employment',
        name: 'Employment',
        component: () => import('../views/employment/index'),
      },
      {
        path: '/employment/detail',
        name: 'EmploymentDetail',
        component: () => import('../views/employment/detail'),
      },
      {
        path: '/import',
        name: 'Import',
        component: () => import('../views/import/index'),
      },
      {
        path: '/abnormity',
        name: 'Abnormity',
        component: () => import('../views/abnormity/index'),
      },
      {
        path: '/setting',
        name: 'Setting',
        component: () => import('../views/setting/index'),
      },
      {
        path: '/setting/keyword',
        name: 'SettingKeyword',
        component: () => import('../views/setting/keyword'),
      },
      {
        path: '/feedback',
        name: 'Feedback',
        component: () => import('../views/feedback/index'),
      },
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/login')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
